<template lang="pug">
.showcase
  FcwNavbar(
    v-if="!hideNavbar"
    :links="navLinks"
    :plain-threshold="navPlainThreshold"
  )
    template(#actions="{ isPlain }")
      FcwNavbarCta(:inverted="isPlain")

    template(#actions-mobile)
      .showcase__mobileActions(
        v-if="(isSmAndDown && showSharingStaticLinks) || showLeasingStaticLinks"
      )
        h4 {{ t('showcase__mobile_menu__download_app__all') }}
        .showcase__mobileActions___actions
          template(v-if="showSharingStaticLinks")
            h6.showcase__mobileActions__appName(v-if="!showSharingDynamicLink") {{ t('common__sharing') }}
            FcwMobileAppStoreButtons(
              mode="dynamic"
              :service-type="ServiceType.Sharing"
              outlined
              button-class="showcase__mobileActions__button"
            )
          template(v-if="showLeasingStaticLinks")
            h6.showcase__mobileActions__appName(v-if="!showLeasingDynamicLink") {{ t('common__leasing') }}
            FcwMobileAppStoreButtons(
              mode="dynamic"
              :service-type="ServiceType.Leasing"
              button-class="showcase__mobileActions__button"
            )
      .showcase__tabletActions(
        v-else-if="!isSmAndDown && clientState.modules.profile"
      )
        FcwButtonLink(
          :location="{ name: 'profile' }"
          color="primary"
          background="neutral--light-5"
        )
          span {{ t('common__my_profile') }}
          FIcon(name="user")
  main
    NuxtPage
  FcwFooter(
    v-if="!hideFooter"
    v-bind="showcaseFooterProps"
  )
  FcwMobileAppStoresModal(
    v-model="isMobileAppStoresModalVisible"
    :sharing-app-features="clientState.mobileAppsOverview.sharing?.features"
    :sharing-app-device-mockup="clientState.mobileAppsOverview.sharing?.device_mockup"
    :leasing-app-features="clientState.mobileAppsOverview.leasing?.features"
    :leasing-app-device-mockup="clientState.mobileAppsOverview.leasing?.device_mockup"
  )
</template>

<style lang="stylus">
main
  min-height 100vh

.showcase__mobileActions
  display flex
  flex-direction column
  width 100%
  gap rem(16)

  h4
    font-size rem(16)

.showcase__mobileActions___actions
  display flex
  flex-wrap wrap
  gap rem(16)

.showcase__mobileActions__button
  max-width rem(320)
</style>

<script lang="ts" setup>
import { ServiceType } from '@/models/enums';

const route = useRoute();
const navPlainThreshold = computed(
  () => (route.meta?.navPlainThreshold as number) ?? 1
);
const hideNavbar = computed(
  () => route.meta?.hideNavbar ?? route.query?.frameless ?? false
);
const hideFooter = computed(
  () => route.meta?.hideFooter ?? route.query?.frameless ?? false
);

const { t } = useClientI18n();
const { clientState } = storeToRefs(useClientStore());
const { isMobileAppStoresModalVisible } = storeToRefs(useGlobalStore());

const { isSmAndDown } = useFBreakpoints();

const navLinks = await useClientNavbar();

const { data: showcaseFooterProps, error } = await useCmsFooter();
useCmsError(error);

const {
  hasStaticLinks: showSharingStaticLinks,
  hasDynamicLink: showSharingDynamicLink,
} = useAppStoreLinks(ServiceType.Sharing);
const {
  hasStaticLinks: showLeasingStaticLinks,
  hasDynamicLink: showLeasingDynamicLink,
} = useAppStoreLinks(ServiceType.Leasing);
</script>
